import {Controller} from "@hotwired/stimulus";
import * as am5 from "@amcharts/amcharts5";
import * as am5flow from "@amcharts/amcharts5/flow";
import {MysteryMindsTheme, setLocale} from "../lib/amcharts_config";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default class extends Controller {
    static values = {chart: Object};
    static targets = ["element"];

    connect() {
        const root = am5.Root.new(this.elementTarget);
        this.networkChart = root;
        setLocale(root, this.chartValue.language);
        root.setThemes([
            MysteryMindsTheme.new(root),
            am5themes_Animated.new(root),
        ]);

        this.initializeChart(root);
    }

    disconnect() {
        this.networkChart.dispose();
    }

    initializeChart(root) {
        root.container.setAll({paddingTop: 60, paddingBottom: 60, paddingLeft: 60, paddingRight: 60});

        const series = this.createSeries(root);
        series.nodes.labels.template.setAll({
            oversizedBehavior: "truncate", // wrap|truncate...
            textType: "radial",
            centerX: 0,
            maxWidth: 110,
            fontSize: 14,
        });

        series.nodes.rectangles.template.setAll({
            tooltipText: "[bold]{name}[/]",
        });

        if (this.chartValue.enableExport) {
            this.enableExport(root);
        }

        this.setupChart(series);
    }

    createSeries(root) {
        const series = root.container.children.push(
            am5flow.ChordDirected.new(root, {
                sourceIdField: this.chartValue.sourceIdField,
                targetIdField: this.chartValue.targetIdField,
                valueField: this.chartValue.valueField,
                sort: "ascending",
                nodeWidth: 15,
                padAngle: 3,
                linkHeadRadius: null,
            })
        );

        series.links.template.set("fillStyle", "source");
        series.nodes.get("colors").set("step", 2);

        return series;
    }

    setupChart(series) {
        series.set("fill", am5.color(this.chartValue.color));
        series.set("stroke", am5.color(this.chartValue.color));
        series.set("strokeWidth", 3);

        series.data.setAll(this.chartValue.data);

        series.appear(3500, 100);
    }

    enableExport(root) {
        this.exporting = am5plugins_exporting.Exporting.new(root, {
            menu: undefined,
            filePrefix: this.chartValue.exportFileName,
            dataSource: this.chartValue.data,
        });
    }

    export() {
        if (!this.chartValue.enableExport) {
            return;
        }

        this.exporting.download("xlsx");
    }
}
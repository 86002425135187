import {Controller} from "@hotwired/stimulus";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {MysteryMindsTheme, setLocale} from "../lib/amcharts_config";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

let lineChart = null;

export default class extends Controller {
    static values = {chart: Object};
    static targets = ["element"];

    connect() {
        const root = am5.Root.new(this.elementTarget);
        this.lineChart = root;
        setLocale(root, this.chartValue.language);

        // set our theme
        root.setThemes([MysteryMindsTheme.new(root)]);
        this.initializeChart(root);
    }

    disconnect() {
        this.lineChart.dispose();
    }

    initializeChart(root) {
        const chart = this.createChart(root);
        this.createAxes(root, chart);
        this.enableCursor(root, chart);

        let series = this.createSeries(root, chart);
        this.enableToolTips(root, series);
        this.enableFill(root, series);
        this.enableGradient(root, series);
        if (this.chartValue.enableBullets) {
            this.enableBullets(root, series);
        }

        if (this.chartValue.enableExport) {
            this.enableExport(root);
        }

        if (this.chartValue.goal !== null) {
            const maxDataValue = Math.max(...this.chartValue.data.map(item => item[this.chartValue.valueName]));
            this.enableGoal(root, chart, maxDataValue);
        }

        this.setupChart(chart, series);
    }

    createChart(root) {
        return root.container.children.push(
            am5xy.XYChart.new(root, {
                layout: root.verticalLayout,
            })
        );
    }

    createAxes(root, chart) {
        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
                min: 0,
                maxPrecision: 0,
            })
        );

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: this.chartValue.categoryName,
            })
        );

        xAxis.data.setAll(this.chartValue.data);

        // add padding top to xAxis
        let xRenderer = xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            paddingTop: 10
        });

        // add padding right to yAxis
        let yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            paddingRight: 10
        });
    }

    createSeries(root, chart) {
        return chart.series.push(
            am5xy.SmoothedXLineSeries.new(root, {
                xAxis: chart.xAxes.getIndex(0),
                yAxis: chart.yAxes.getIndex(0),
                valueYField: this.chartValue.valueName,
                categoryXField: this.chartValue.categoryName,
            })
        );
    }

    enableCursor(root, chart) {
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: 'none',
        }));

        cursor.lineX.setAll({
            stroke: am5.color(0x626b85),
            strokeWidth: 2,
            strokeDasharray: [5, 5],
        });

        // hide the lineY cursor
        cursor.lineY.setAll({
            visible: false,
        });
    }

    enableToolTips(root, series) {
        let tooltip = am5.Tooltip.new(root, {
            labelText: this.chartValue.tooltipText,
            pointerOrientation: "horizontal",
        });

        tooltip.get("background").setAll({
            stroke: am5.color(0x231f20),
            fillOpacity: 1,
            strokeOpacity: 0.6
        });

        series.set('tooltip', tooltip);
    }

    enableFill(root, series) {
        series.fills.template.setAll({
            visible: true,
            fillOpacity: 0.3,
            stroke: am5.color(0x333333),
        });
    }

    enableGradient(root, series) {
        series.fills.template.set("fillGradient", am5.LinearGradient.new(root, {
            stops: [{
                opacity: 0.8
            }, {
                opacity: 0.3
            }],
            rotation: 90
        }));
    }

    enableBullets(root, series) {
        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationY: 0,
                sprite: am5.Circle.new(root, {
                    radius: 6,
                    stroke: root.interfaceColors.get("background"),
                    strokeWidth: 2,
                    fill: series.get("fill"),
                }),
            });
        });
    }


    enableGoal(root, chart, maxDataValue) {
        let yAxis = chart.yAxes.getIndex(0);
        let goalRange = yAxis.createAxisRange(yAxis.makeDataItem({
            value: this.chartValue.goal.value,
            affectsMinMax: this.chartValue.goal.value > maxDataValue,
        }));

        goalRange.get("grid").setAll({
            forceHidden: false,
            stroke: am5.color(0x626b85),
            strokeWidth: 2,
            strokeDasharray: [5, 5],
            strokeOpacity: 0.8
        });

        goalRange.get("label").setAll({
            text: this.chartValue.goal.label,
            inside: true,
            fill: am5.color(0xffffff),
            fillOpacity: 1,
            centerX: 0,
            dx: 0,
            dy: -12.5, // Move label up, so it sits on top of the goal line
            paddingBottom: 5,
            background: am5.RoundedRectangle.new(root, {
                fill: am5.color(0x626b85),
                fillOpacity: 1,
                cornerRadiusTL: 0,
                cornerRadiusTR: 5,
                cornerRadiusBL: 0,
                cornerRadiusBR: 0,
                opacity: 1
            })
        });
    }

    setupChart(chart, series) {
        series.set("fill", am5.color(this.chartValue.color));
        series.set("stroke", am5.color(this.chartValue.color));
        series.set("strokeWidth", 3);

        series.data.setAll(this.chartValue.data);

        series.appear(3500, 100);
        chart.appear(3500, 100);
    }

    enableExport(root) {
        this.exporting = am5plugins_exporting.Exporting.new(root, {
            menu: undefined,
            filePrefix: this.chartValue.exportFileName,
            dataSource: this.chartValue.data,
        });
    }

    export() {
        if (!this.chartValue.enableExport) {
            return;
        }

        this.exporting.download("xlsx");
    }
}
